* {
  background-attachment: scroll !important;
}

.mePicLayer2 {
  display: none !important;
}

.activeAnim .mePicLayer3 {
  background-repeat: no-repeat;
  background-size: 480px;
  width: 480px;
  height: 480px;
  opacity: 0;
  animation: reducedMotionPPFade 1 forwards .4s;
}

.faceSwitchIcon {
  transition: unset;
}

@keyframes reducedMotionPPFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
