/* PRELOAD */

.preload {
  display: flex;
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  position: fixed;
  left:0;
  top:0;
  pointer-events: none;
  div, img {
    opacity: 0;
    width: 100%;
  }
}

/*  BASE STYLES */

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.4) transparent;
  &::-webkit-scrollbar-track {
    opacity: 0;
  }
  &::-webkit-scrollbar {
    width: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background-color: #FFF6;
  }
}

.preload {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -9999;
  opacity: 0;
}

html {
  font: 125% $readex;
}

body {
  font-size: 1rem;
  position: relative;
  color: $fgColor;
  background-color: $bgColor;
  min-width: 400px;
  min-height: 100vh;
}

a,
.upArrow {
  text-decoration: none;
  color: inherit;
  transition: color .3s;
  &:hover {
    color: $amber;
  }
}

img {
  width: 100%;
  display: block;
}

ul {
  list-style: none;
}

h1 {
  font-size: 4.5rem;
  font-weight: 500;
  color: $lightBlue;
}

h2 {
  font-size: 3.5rem;
  font-weight: 400;
  color: $fgColor;
}

h3 {
  text-transform: uppercase;
  color: $lightBlue;
}

h4 {
  font-family: $special;
  font-size: 2rem;
  margin: 100px 0 20px 30px;
  color: inherit;
  &.fh4 {
    margin: 0 0 20px 30px;
  }
}

h5 {
  color: $lightBlue;
}

h6 {
  text-transform: uppercase;
  font-size: 0.6rem;
  line-height: 26px;
  color: $lightBlue;
  &.ds {
    font-size: .4rem;
    text-align: center;
    transform: translateY(-5px);
    font-style: italic;
    color: #777;
    text-shadow: 0 0 5px #000
  }
  &.smallCaption {
    font-size: .5rem;
    font-weight: 400;
    font-style: italic;
    position: relative;
    bottom: 8px;
  }
}

section {
  // padding: 80px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

// main section {
//   position: relative;
//   overflow: visible;
//   &:before {
//     content: '';
//     position: absolute;
//     width: 100%;
//     height: 100px;
//     top: -50px;
//     backdrop-filter: blur(25px);
//     mask-image: linear-gradient(180deg, transparent 0%, #000 50%, transparent 100%);
//     z-index: 1;
//   }
// }


/*  COMMON ELEMENTS */

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  width: 90%;
}

.sectionBackground {
  background-size: cover;
  background-position: center;
}

.sectionContainer {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.sectionTitle {
  font-size: 6rem;
  opacity: 0.5;
  z-index: 0;
  user-select: none;
  cursor: default;
  z-index: 0;
  height: 150px;
  transform: rotate(17deg);
  color: $lightBlue;
  text-shadow: 0 0 10px #000;
}

.backgroundStyle {
  position: absolute;
  // z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.5;
  background-image:
    linear-gradient(-25deg, $darkGrey 0 27.85%, transparent 28% 100%),
    linear-gradient(-65deg, $darkSlate 0% 29.85%, transparent 30% 100%),
    linear-gradient(-80deg, $slate 0 34.85%, transparent 35% 100%),
    linear-gradient($bgColor, $bgColor);
}

.fadeBorder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 50px;
  z-index: 1;
  background-image: linear-gradient(#000 10%, transparent 33% 67%, #000 90%);
}

footer {
  text-align: center;
  text-transform: uppercase;
  font-size: .7rem;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  padding: 5px;
  color: #CCC;
  letter-spacing: 5px;
}
