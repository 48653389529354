//..  FONTS
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;600;700&family=Special+Elite&display=swap');

$readex: 'Readex Pro', sans-serif;
$special: 'Special Elite', serif;

//..  COLOURS
$bgColor: #181A1F;
$bgColorLight: #FFF;
$fgColor: #EEEFFF;
$accent1: #48A0F0;
$accent2: #EF9E76;
$darkGrey: #3b3b3f; //     rgba(59,59,63,1.000)
$faintBlue: #9c97c8; //    rgba(156,151,200,1.000)
$faintGrey: #a3b2c4; //    rgba(163,178,196,1.000)
$darkSlate: #484a5f; //    rgba(72,74,95,1.000)
$fadedSlate: #9A8FAA;
$slate: #567;
$lightBlue: #48a0f0; //    rgba(72,160,240,1.000)
$amber: #e99b36; //        rgba(233,155,54,1.000)
