.skillsContainer {
  display: flex;
  justify-content: space-between;
}

.skillsText {
  font-size: 1.1rem;
}

.companiesList {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  @media screen and (max-width: 565px) {
    flex-wrap: wrap;
  }
}

.companyLogoItem {
  border-radius: 10px;
  max-width: 300px;
  width: calc(25% - 30px);
  min-width: 100px;
  overflow: hidden;
  margin: 0 15px;
  transform-style: preserve-3d;
  transform: perspective(500px) rotateY(0) rotateX(0);
  position: relative;
  box-shadow: -10px 10px 20px #0004;
  @media screen and (max-width: 565px) {
    transition: unset !important;
    max-width: unset;
    min-width: unset;
    width: 150px !important;
    margin: 0 10px 15px;
  }
}

.shine {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(45deg, #FFF1 0 30%, #FFF4 48% 52%, #FFF1 70% 100%);
  background-position: 0;
  background-size: 1000px 1000px;
  background-repeat: repeat;
  opacity: 0;
  border-radius: 10px;
}

.shineReset {
  transition: all 1s;
}

.companiesContainer {
  position: relative;
  width: 100%;
  h4 {
    font-size: 1.4rem;
    margin: 50px 0 10px;
  }
}
