@media screen and (max-width:1024px) {
  .wrapper {
    width: 92.5%;
  }

  .headerContainer {
    min-height: 360px;
    h3 {
      width: 750px;
    }
  }

  .centerHeaderItems {
    width: 750px;
    height: 230px;
  }

  .faceSwitchIcon {
    height: 240px;
  }

  .headerText {
    width: 360px;
    margin-top: 15px;
    .namePro, h2 {
      padding-left: 0;
    }
  }

  h1 {
    font-size: 3.2rem;
    line-height: 4rem;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .profilePic {
    width: 363px;
    height: 363px;
  }
}

@media screen and (max-width:768px) {
  .wrapper {
    width: 95%;
  }

  .profilePic {
    display: none !important;
  }

  .headerContainer {
    min-height: 360px;
  }

  .centerHeaderItems {
    justify-content: center;
    max-width: 100%;
    min-width: 380px;
  }

  .headerContainer h3 {
    width: unset;
  }

  .learnMore {
    width: 100%;
    font-size: 1.4rem;
  }
}
