.lightMode {
  color: #000;
  background-color: #EFEFEF;

  a,
  .upArrow {
    &:hover {
      color: darken($amber, 10%);
    }
  }

  h1 {
    color: darken($lightBlue, 20%);
  }

  h2 {
    color: #000;
  }

  h3 {
    color: darken($amber, 10%);
  }

  h5 {
    color: darken($lightBlue, 20%);
  }

  h6 {
    color: darken($lightBlue, 20%);
  }

  .sectionTitle {
    color: darken($lightBlue, 20%);
  }

  .backgroundStyle {
    background-image:
      linear-gradient(-25deg, $faintGrey 0 27.85%, transparent 28% 100%),
      linear-gradient(-65deg, $fadedSlate 0% 29.85%, transparent 30% 100%),
      linear-gradient(-80deg, rgba(163, 178, 196, 0.45) 0 34.85%, transparent 35% 100%),
      linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
    }

  .sectionBackground {
    background: $bgColorLight;
  }

  .centerHeaderItems {
    background: #FFF;
  }
}
