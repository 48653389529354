.profilePic {
  position: relative;
  width: 483px;
  height: 483px;
  overflow: hidden;
  animation: none;
  border-radius: 0 75% 0 0;
  border-top: 3px solid $accent1;
  border-right: 3px solid $accent1;
  top: -3px;
  cursor: pointer;
  img, div {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
}

.mePicLayer1 {
  max-width: 100%;
  max-height: 100%;
  position: relative !important;
}

.mePicLayer3 {
  .mePicLayerImg1 {
    z-index: 0;
  }
  .mePicLayerImg2 {
    z-index: 1;
  }
}

.profilePicAlt .mePicLayer3 {
  .mePicLayerImg1 {
    z-index: 1;
  }
  .mePicLayerImg2 {
    z-index: 0;
  }
}

.faceSwitchIcon {
  background: #000A;
  color: #FFF;
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 15;
  opacity: 0;
  pointer-events: none;
  padding-right: 50px;
  svg {
    width: 50px;
    height: 50px;
  }
}

.photoSlashPaint {
  font-size: 60px;
  padding: 0 10px 0 20px;
}

.headerSection {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  min-height: 768px;
}

.headerContainer {
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 485px;
  // margin-bottom: calc(15px + 4vh);
  h3 {
    margin-top: 20px;
    width: 1000px;
    text-align: right;
    color: $amber;
  }
}

.centerHeaderItems {
  display: flex;
  width: 1000px;
  height: 300px;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  border: 3px solid $lightBlue;
  border-radius: 5px;
  overflow: hidden;
  background: #000;
  h2 {
    z-index: 5;
  }
}

.headerText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 500px;
  line-height: 4.8rem;
  z-index: 10;
  .namePro,
  h2 {
    width: 500px;
    padding-left: 50px;
  }
}

.namePro {
  display: flex;
  align-items: baseline;
  p {
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-left: 10px;
  }
}

.headerTitles {
  margin-top: 20px;
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learnMore {
  position: relative;
  text-align: center;
  font-size: 2rem;
}

// .learnMore:after, .learnMore:before {
//   border-bottom: 2px solid #FFF6;
//   border-right: 2px solid #FFF6;
//   content: '';
//   width: 16px;
//   height: 16px;
//   display: block;
//   position: absolute;
//   left: calc(50% - 8px);
//   transform: scaleX(2.5) rotate(45deg);
// }

// .learnMore:after {
//   top: 4.5rem;
// }

// .learnMore:before {
//   top: 5.25rem
// }

.learnMore {
  width: 80%;
  margin: 0 auto;
  a {
    color: $accent2;
    &:hover {
      color: $amber;
    }
  }
  h5 {
    max-width: 500px
  }
  .oldPage {
    max-width: 500px;
    font-size: .8rem;
    margin-top: 50px;
    p:last-child {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 800px) {
    font-size: 1.6rem;
  }
}

.scrollDown {
  font-size: 1.7rem;
  margin-bottom: calc(15px + 4vh);
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  @media screen and (max-width: 500px) {
    margin-top: 15%;
    width: 100%;
  }
}

.resumeHeadingBottom {
  margin-top: 15px;
}
