// DEV ICONS

.devIcons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  margin: 15px 20px;
  text-align: center;
  p {
    font-size: .8rem;
    font-weight: 500;
    margin: 10px 10px 0;
  }
  svg {
    width: 50px;
    fill: #9c97c8;
  }
}

.devIcon {
  max-width: 100px;
}

.devIconsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.devIconsBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 100px;
}

// CODE PEN ASIDE

.codePenAside {
  display: flex;
  align-items: center;
  background-image: url("../../assets/cparrow.png");
  background-repeat: no-repeat;
  background-position: 100% calc(50% - 80px);
  background-size: 50px;
  padding-right: 10px;
  transform: scale(.85);
  h3 {
    text-transform: unset;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
    padding: 0 50px 5px;
    width: 300px;
  }
  @media screen and (max-width: 650px) {
    transform: scale(.75);
    h3 {
      padding: 0 50px 5px 0;
      width: 200px;
    }
    .codePenBox {
      margin: unset;
    }
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.codePenBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin: auto;
  padding: 20px;
  border: 1px solid #FFF5;
  border-radius: 10px;
  background: #484a5f33;
  box-shadow: 0 0 30px #0001;
  transition: background .3s, box-shadow .3s;
  &:hover {
    background: #BDF2;
    box-shadow: 0 0 50px #0008;
  }
  p {
    font-size: .8rem;
    margin-top: 5px;
  }
}

.codePenImage {
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
  img {
    width: 150px;
  }
}